export const routes = [
  {
    path: '/',
    name: 'root',
    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/index.vue'),
    /* no children */
  },
  {
    path: '/activities',
    /* internal name: '/activities' */
    /* no component */
    children: [
      {
        path: '',
        name: '/activities/',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/activities/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/admins',
    /* internal name: '/admins' */
    /* no component */
    children: [
      {
        path: '',
        name: '/admins/',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/admins/index.vue'),
        /* no children */
      },
      {
        path: ':admin_id/edit',
        name: '/admins/[admin_id].edit',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/admins/[admin_id].edit.vue'),
        /* no children */
      },
      {
        path: 'new',
        name: '/admins/new',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/admins/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/answer_inputs',
    /* internal name: '/answer_inputs' */
    /* no component */
    children: [
      {
        path: '',
        name: '/answer_inputs/',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/answer_inputs/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/answer_inputs/[id]',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/answer_inputs/[id].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/answer_sheets',
    /* internal name: '/answer_sheets' */
    /* no component */
    children: [
      {
        path: 'unfinished',
        name: '/answer_sheets/unfinished',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/answer_sheets/unfinished.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/assortments',
    /* internal name: '/assortments' */
    /* no component */
    children: [
      {
        path: ':assortment_id/edit',
        name: '/assortments/[assortment_id].edit',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/assortments/[assortment_id].edit.vue'),
        /* no children */
      },
      {
        path: 'new',
        name: '/assortments/new',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/assortments/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/editions',
    /* internal name: '/editions' */
    /* no component */
    children: [
      {
        path: ':edition_id',
        /* internal name: '/editions/[edition_id]' */
        /* no component */
        children: [
          {
            path: 'schoolbooks',
            /* internal name: '/editions/[edition_id]/schoolbooks' */
            /* no component */
            children: [
              {
                path: ':id/edit',
                name: '/editions/[edition_id]/schoolbooks/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/editions/[edition_id]/schoolbooks/[id].edit.vue'),
                /* no children */
              },
              {
                path: ':id/edit_knowledges',
                name: '/editions/[edition_id]/schoolbooks/[id].edit_knowledges',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/editions/[edition_id]/schoolbooks/[id].edit_knowledges.vue'),
                /* no children */
              },
              {
                path: ':id/edit_question_categories',
                name: '/editions/[edition_id]/schoolbooks/[id].edit_question_categories',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/editions/[edition_id]/schoolbooks/[id].edit_question_categories.vue'),
                /* no children */
              },
              {
                path: 'new',
                name: '/editions/[edition_id]/schoolbooks/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/editions/[edition_id]/schoolbooks/new.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: ':id',
        name: '/editions/[id]',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/editions/[id].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/homework_check_feedbacks',
    /* internal name: '/homework_check_feedbacks' */
    /* no component */
    children: [
      {
        path: '',
        name: '/homework_check_feedbacks/',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/homework_check_feedbacks/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/homework_check_feedbacks/[id]',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/homework_check_feedbacks/[id].vue'),
        /* no children */
      },
      {
        path: ':id/edit',
        name: '/homework_check_feedbacks/[id].edit',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/homework_check_feedbacks/[id].edit.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/homework_papers',
    /* internal name: '/homework_papers' */
    /* no component */
    children: [
      {
        path: '',
        name: '/homework_papers/',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/homework_papers/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/login.vue'),
    /* no children */
    meta: {
      "auth": false,
      "layout": "session"
    }
  },
  {
    path: '/media_resources',
    /* internal name: '/media_resources' */
    /* no component */
    children: [
      {
        path: '',
        name: '/media_resources/',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/media_resources/index.vue'),
        /* no children */
      },
      {
        path: 'new',
        name: '/media_resources/new',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/media_resources/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/personations',
    /* internal name: '/personations' */
    /* no component */
    children: [
      {
        path: ':token',
        name: '/personations/[token]',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/personations/[token].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/provinces',
    /* internal name: '/provinces' */
    /* no component */
    children: [
      {
        path: '',
        name: '/provinces/',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/provinces/index.vue'),
        /* no children */
      },
      {
        path: ':province_id',
        /* internal name: '/provinces/[province_id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/provinces/[province_id]/',
            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/provinces/[province_id]/index.vue'),
            /* no children */
          },
          {
            path: 'cities',
            /* internal name: '/provinces/[province_id]/cities' */
            /* no component */
            children: [
              {
                path: '',
                name: '/provinces/[province_id]/cities/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/provinces/[province_id]/cities/index.vue'),
                /* no children */
              },
              {
                path: ':city_id',
                /* internal name: '/provinces/[province_id]/cities/[city_id]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/provinces/[province_id]/cities/[city_id]/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/provinces/[province_id]/cities/[city_id]/index.vue'),
                    /* no children */
                  },
                  {
                    path: 'districts',
                    /* internal name: '/provinces/[province_id]/cities/[city_id]/districts' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/provinces/[province_id]/cities/[city_id]/districts/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/provinces/[province_id]/cities/[city_id]/districts/index.vue'),
                        /* no children */
                      },
                      {
                        path: ':district_id',
                        /* internal name: '/provinces/[province_id]/cities/[city_id]/districts/[district_id]' */
                        /* no component */
                        children: [
                          {
                            path: 'edit',
                            name: '/provinces/[province_id]/cities/[city_id]/districts/[district_id]/edit',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/provinces/[province_id]/cities/[city_id]/districts/[district_id]/edit.vue'),
                            /* no children */
                          }
                        ],
                      },
                      {
                        path: 'new',
                        name: '/provinces/[province_id]/cities/[city_id]/districts/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/provinces/[province_id]/cities/[city_id]/districts/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'edit',
                    name: '/provinces/[province_id]/cities/[city_id]/edit',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/provinces/[province_id]/cities/[city_id]/edit.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'new',
                name: '/provinces/[province_id]/cities/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/provinces/[province_id]/cities/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'edit',
            name: '/provinces/[province_id]/edit',
            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/provinces/[province_id]/edit.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'new',
        name: '/provinces/new',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/provinces/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/schoolbooks',
    /* internal name: '/schoolbooks' */
    /* no component */
    children: [
      {
        path: ':schoolbook_id',
        /* internal name: '/schoolbooks/[schoolbook_id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/schoolbooks/[schoolbook_id]/',
            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/schoolbooks/[schoolbook_id]/index.vue'),
            /* no children */
          },
          {
            path: 'spreads',
            /* internal name: '/schoolbooks/[schoolbook_id]/spreads' */
            /* no component */
            children: [
              {
                path: '',
                name: '/schoolbooks/[schoolbook_id]/spreads/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/schoolbooks/[schoolbook_id]/spreads/index.vue'),
                /* no children */
              },
              {
                path: ':id/edit',
                name: '/schoolbooks/[schoolbook_id]/spreads/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/schoolbooks/[schoolbook_id]/spreads/[id].edit.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'textbooks',
            /* internal name: '/schoolbooks/[schoolbook_id]/textbooks' */
            /* no component */
            children: [
              {
                path: '',
                name: '/schoolbooks/[schoolbook_id]/textbooks/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/schoolbooks/[schoolbook_id]/textbooks/index.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'unit_modules',
            /* internal name: '/schoolbooks/[schoolbook_id]/unit_modules' */
            /* no component */
            children: [
              {
                path: '',
                name: '/schoolbooks/[schoolbook_id]/unit_modules/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/schoolbooks/[schoolbook_id]/unit_modules/index.vue'),
                /* no children */
              },
              {
                path: ':id/edit',
                name: '/schoolbooks/[schoolbook_id]/unit_modules/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/schoolbooks/[schoolbook_id]/unit_modules/[id].edit.vue'),
                /* no children */
              },
              {
                path: ':unit_module_id',
                /* internal name: '/schoolbooks/[schoolbook_id]/unit_modules/[unit_module_id]' */
                /* no component */
                children: [
                  {
                    path: 'units',
                    /* internal name: '/schoolbooks/[schoolbook_id]/unit_modules/[unit_module_id]/units' */
                    /* no component */
                    children: [
                      {
                        path: ':id/edit',
                        name: '/schoolbooks/[schoolbook_id]/unit_modules/[unit_module_id]/units/[id].edit',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/schoolbooks/[schoolbook_id]/unit_modules/[unit_module_id]/units/[id].edit.vue'),
                        /* no children */
                      },
                      {
                        path: ':unit_id',
                        /* internal name: '/schoolbooks/[schoolbook_id]/unit_modules/[unit_module_id]/units/[unit_id]' */
                        /* no component */
                        children: [
                          {
                            path: 'unit_texts',
                            /* internal name: '/schoolbooks/[schoolbook_id]/unit_modules/[unit_module_id]/units/[unit_id]/unit_texts' */
                            /* no component */
                            children: [
                              {
                                path: ':id',
                                name: '/schoolbooks/[schoolbook_id]/unit_modules/[unit_module_id]/units/[unit_id]/unit_texts/[id]',
                                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/schoolbooks/[schoolbook_id]/unit_modules/[unit_module_id]/units/[unit_id]/unit_texts/[id].vue'),
                                /* no children */
                              },
                              {
                                path: ':id/edit',
                                name: '/schoolbooks/[schoolbook_id]/unit_modules/[unit_module_id]/units/[unit_id]/unit_texts/[id].edit',
                                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/schoolbooks/[schoolbook_id]/unit_modules/[unit_module_id]/units/[unit_id]/unit_texts/[id].edit.vue'),
                                /* no children */
                              },
                              {
                                path: 'new',
                                name: '/schoolbooks/[schoolbook_id]/unit_modules/[unit_module_id]/units/[unit_id]/unit_texts/new',
                                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/schoolbooks/[schoolbook_id]/unit_modules/[unit_module_id]/units/[unit_id]/unit_texts/new.vue'),
                                /* no children */
                              }
                            ],
                          }
                        ],
                      },
                      {
                        path: 'new',
                        name: '/schoolbooks/[schoolbook_id]/unit_modules/[unit_module_id]/units/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/schoolbooks/[schoolbook_id]/unit_modules/[unit_module_id]/units/new.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              },
              {
                path: 'new',
                name: '/schoolbooks/[schoolbook_id]/unit_modules/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/schoolbooks/[schoolbook_id]/unit_modules/new.vue'),
                /* no children */
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/semesters',
    /* internal name: '/semesters' */
    /* no component */
    children: [
      {
        path: '',
        name: '/semesters/',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/semesters/index.vue'),
        /* no children */
      },
      {
        path: ':semester_id/edit',
        name: '/semesters/[semester_id].edit',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/semesters/[semester_id].edit.vue'),
        /* no children */
      },
      {
        path: 'new',
        name: '/semesters/new',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/semesters/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/sites',
    /* internal name: '/sites' */
    /* no component */
    children: [
      {
        path: '',
        name: '/sites/',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/index.vue'),
        /* no children */
      },
      {
        path: ':site_id',
        /* internal name: '/sites/[site_id]' */
        /* no component */
        children: [
          {
            path: 'agents',
            /* internal name: '/sites/[site_id]/agents' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/agents/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/agents/index.vue'),
                /* no children */
              },
              {
                path: ':agent_id',
                /* internal name: '/sites/[site_id]/agents/[agent_id]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/sites/[site_id]/agents/[agent_id]/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/agents/[agent_id]/index.vue'),
                    /* no children */
                  },
                  {
                    path: 'agent_balance_records',
                    /* internal name: '/sites/[site_id]/agents/[agent_id]/agent_balance_records' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/agents/[agent_id]/agent_balance_records/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/agents/[agent_id]/agent_balance_records/index.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/sites/[site_id]/agents/[agent_id]/agent_balance_records/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/agents/[agent_id]/agent_balance_records/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'agent_point_records',
                    /* internal name: '/sites/[site_id]/agents/[agent_id]/agent_point_records' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/agents/[agent_id]/agent_point_records/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/agents/[agent_id]/agent_point_records/index.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/sites/[site_id]/agents/[agent_id]/agent_point_records/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/agents/[agent_id]/agent_point_records/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'edit',
                    name: '/sites/[site_id]/agents/[agent_id]/edit',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/agents/[agent_id]/edit.vue'),
                    /* no children */
                  },
                  {
                    path: 'personation',
                    name: '/sites/[site_id]/agents/[agent_id]/personation',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/agents/[agent_id]/personation.vue'),
                    /* no children */
                  },
                  {
                    path: 'schools',
                    /* internal name: '/sites/[site_id]/agents/[agent_id]/schools' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/agents/[agent_id]/schools/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/agents/[agent_id]/schools/index.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/sites/[site_id]/agents/[agent_id]/schools/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/agents/[agent_id]/schools/new.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              },
              {
                path: 'new',
                name: '/sites/[site_id]/agents/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/agents/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'batch_coupons_form',
            /* internal name: '/sites/[site_id]/batch_coupons_form' */
            /* no component */
            children: [
              {
                path: 'new',
                name: '/sites/[site_id]/batch_coupons_form/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/batch_coupons_form/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'bundles',
            /* internal name: '/sites/[site_id]/bundles' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/bundles/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/bundles/index.vue'),
                /* no children */
              },
              {
                path: ':bundle_id',
                /* internal name: '/sites/[site_id]/bundles/[bundle_id]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/sites/[site_id]/bundles/[bundle_id]/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/bundles/[bundle_id]/index.vue'),
                    /* no children */
                  },
                  {
                    path: 'edit',
                    name: '/sites/[site_id]/bundles/[bundle_id]/edit',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/bundles/[bundle_id]/edit.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'new',
                name: '/sites/[site_id]/bundles/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/bundles/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'clazz_daily_activities',
            /* internal name: '/sites/[site_id]/clazz_daily_activities' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/clazz_daily_activities/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/clazz_daily_activities/index.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'coupon_active_batches',
            /* internal name: '/sites/[site_id]/coupon_active_batches' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/coupon_active_batches/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/coupon_active_batches/index.vue'),
                /* no children */
              },
              {
                path: 'new',
                name: '/sites/[site_id]/coupon_active_batches/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/coupon_active_batches/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'coupon_clazz_code_forms',
            /* internal name: '/sites/[site_id]/coupon_clazz_code_forms' */
            /* no component */
            children: [
              {
                path: 'new',
                name: '/sites/[site_id]/coupon_clazz_code_forms/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/coupon_clazz_code_forms/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'coupons',
            /* internal name: '/sites/[site_id]/coupons' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/coupons/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/coupons/index.vue'),
                /* no children */
              },
              {
                path: ':id',
                name: '/sites/[site_id]/coupons/[id]',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/coupons/[id].vue'),
                /* no children */
              },
              {
                path: ':id/edit',
                name: '/sites/[site_id]/coupons/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/coupons/[id].edit.vue'),
                /* no children */
              },
              {
                path: 'new',
                name: '/sites/[site_id]/coupons/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/coupons/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'dealers',
            /* internal name: '/sites/[site_id]/dealers' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/dealers/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/dealers/index.vue'),
                /* no children */
              },
              {
                path: ':id/edit',
                name: '/sites/[site_id]/dealers/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/dealers/[id].edit.vue'),
                /* no children */
              },
              {
                path: 'new',
                name: '/sites/[site_id]/dealers/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/dealers/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'evaluation_shengtong_records',
            /* internal name: '/sites/[site_id]/evaluation_shengtong_records' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/evaluation_shengtong_records/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/evaluation_shengtong_records/index.vue'),
                /* no children */
              },
              {
                path: ':id',
                name: '/sites/[site_id]/evaluation_shengtong_records/[id]',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/evaluation_shengtong_records/[id].vue'),
                /* no children */
              },
              {
                path: 'new',
                name: '/sites/[site_id]/evaluation_shengtong_records/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/evaluation_shengtong_records/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'evaluations',
            /* internal name: '/sites/[site_id]/evaluations' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/evaluations/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/evaluations/index.vue'),
                /* no children */
              },
              {
                path: ':evaluation_id',
                /* internal name: '/sites/[site_id]/evaluations/[evaluation_id]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/sites/[site_id]/evaluations/[evaluation_id]/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/evaluations/[evaluation_id]/index.vue'),
                    /* no children */
                  },
                  {
                    path: 'adjust_records',
                    /* internal name: '/sites/[site_id]/evaluations/[evaluation_id]/adjust_records' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/evaluations/[evaluation_id]/adjust_records/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/evaluations/[evaluation_id]/adjust_records/index.vue'),
                        /* no children */
                      },
                      {
                        path: ':adjust_record_id',
                        /* internal name: '/sites/[site_id]/evaluations/[evaluation_id]/adjust_records/[adjust_record_id]' */
                        /* no component */
                        children: [
                          {
                            path: 'edit',
                            name: '/sites/[site_id]/evaluations/[evaluation_id]/adjust_records/[adjust_record_id]/edit',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/evaluations/[evaluation_id]/adjust_records/[adjust_record_id]/edit.vue'),
                            /* no children */
                          }
                        ],
                      },
                      {
                        path: 'new',
                        name: '/sites/[site_id]/evaluations/[evaluation_id]/adjust_records/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/evaluations/[evaluation_id]/adjust_records/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'courseware_relations',
                    /* internal name: '/sites/[site_id]/evaluations/[evaluation_id]/courseware_relations' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/evaluations/[evaluation_id]/courseware_relations/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/evaluations/[evaluation_id]/courseware_relations/index.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/sites/[site_id]/evaluations/[evaluation_id]/courseware_relations/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/evaluations/[evaluation_id]/courseware_relations/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'edit',
                    name: '/sites/[site_id]/evaluations/[evaluation_id]/edit',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/evaluations/[evaluation_id]/edit.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'new',
                name: '/sites/[site_id]/evaluations/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/evaluations/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'exam_plans',
            /* internal name: '/sites/[site_id]/exam_plans' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/exam_plans/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/exam_plans/index.vue'),
                /* no children */
              },
              {
                path: ':id',
                /* internal name: '/sites/[site_id]/exam_plans/[id]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/sites/[site_id]/exam_plans/[id]/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/exam_plans/[id]/index.vue'),
                    /* no children */
                  },
                  {
                    path: 'exam_adjust_records',
                    /* internal name: '/sites/[site_id]/exam_plans/[id]/exam_adjust_records' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/exam_plans/[id]/exam_adjust_records/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/exam_plans/[id]/exam_adjust_records/index.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/sites/[site_id]/exam_plans/[id]/exam_adjust_records/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/exam_plans/[id]/exam_adjust_records/new.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              }
            ],
          },
          {
            path: 'examination_parsing_records',
            /* internal name: '/sites/[site_id]/examination_parsing_records' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/examination_parsing_records/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/examination_parsing_records/index.vue'),
                /* no children */
              },
              {
                path: ':id',
                /* internal name: '/sites/[site_id]/examination_parsing_records/[id]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/sites/[site_id]/examination_parsing_records/[id]/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/examination_parsing_records/[id]/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'new',
                name: '/sites/[site_id]/examination_parsing_records/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/examination_parsing_records/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'examinations',
            /* internal name: '/sites/[site_id]/examinations' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/examinations/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/examinations/index.vue'),
                /* no children */
              },
              {
                path: ':examination_id',
                /* internal name: '/sites/[site_id]/examinations/[examination_id]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/sites/[site_id]/examinations/[examination_id]/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/examinations/[examination_id]/index.vue'),
                    /* no children */
                  },
                  {
                    path: 'edit',
                    name: '/sites/[site_id]/examinations/[examination_id]/edit',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/examinations/[examination_id]/edit.vue'),
                    /* no children */
                  },
                  {
                    path: 'parse_records',
                    /* internal name: '/sites/[site_id]/examinations/[examination_id]/parse_records' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/examinations/[examination_id]/parse_records/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/examinations/[examination_id]/parse_records/index.vue'),
                        /* no children */
                      },
                      {
                        path: ':id',
                        name: '/sites/[site_id]/examinations/[examination_id]/parse_records/[id]',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/examinations/[examination_id]/parse_records/[id].vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'schools',
                    /* internal name: '/sites/[site_id]/examinations/[examination_id]/schools' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/examinations/[examination_id]/schools/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/examinations/[examination_id]/schools/index.vue'),
                        /* no children */
                      },
                      {
                        path: 'batch_new',
                        name: '/sites/[site_id]/examinations/[examination_id]/schools/batch_new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/examinations/[examination_id]/schools/batch_new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'textbooks',
                    /* internal name: '/sites/[site_id]/examinations/[examination_id]/textbooks' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/examinations/[examination_id]/textbooks/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/examinations/[examination_id]/textbooks/index.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/sites/[site_id]/examinations/[examination_id]/textbooks/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/examinations/[examination_id]/textbooks/new.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              },
              {
                path: 'new',
                name: '/sites/[site_id]/examinations/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/examinations/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'group_upload_infos',
            /* internal name: '/sites/[site_id]/group_upload_infos' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/group_upload_infos/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/group_upload_infos/index.vue'),
                /* no children */
              },
              {
                path: ':group_upload_info_id',
                name: '/sites/[site_id]/group_upload_infos/[group_upload_info_id]',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/group_upload_infos/[group_upload_info_id].vue'),
                /* no children */
              },
              {
                path: ':group_upload_info_id/edit',
                name: '/sites/[site_id]/group_upload_infos/[group_upload_info_id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/group_upload_infos/[group_upload_info_id].edit.vue'),
                /* no children */
              },
              {
                path: ':group_upload_info_id/preview',
                name: '/sites/[site_id]/group_upload_infos/[group_upload_info_id].preview',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/group_upload_infos/[group_upload_info_id].preview.vue'),
                /* no children */
              },
              {
                path: 'new',
                name: '/sites/[site_id]/group_upload_infos/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/group_upload_infos/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'jackpots',
            /* internal name: '/sites/[site_id]/jackpots' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/jackpots/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/jackpots/index.vue'),
                /* no children */
              },
              {
                path: ':id',
                name: '/sites/[site_id]/jackpots/[id]',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/jackpots/[id].vue'),
                /* no children */
              },
              {
                path: ':id/edit',
                name: '/sites/[site_id]/jackpots/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/jackpots/[id].edit.vue'),
                /* no children */
              },
              {
                path: 'new',
                name: '/sites/[site_id]/jackpots/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/jackpots/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'membership_batch_records',
            /* internal name: '/sites/[site_id]/membership_batch_records' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/membership_batch_records/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/membership_batch_records/index.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'notices',
            /* internal name: '/sites/[site_id]/notices' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/notices/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/notices/index.vue'),
                /* no children */
              },
              {
                path: ':notice_id',
                /* internal name: '/sites/[site_id]/notices/[notice_id]' */
                /* no component */
                children: [
                  {
                    path: 'edit',
                    name: '/sites/[site_id]/notices/[notice_id]/edit',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/notices/[notice_id]/edit.vue'),
                    /* no children */
                  },
                  {
                    path: 'push_notification_records',
                    /* internal name: '/sites/[site_id]/notices/[notice_id]/push_notification_records' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/notices/[notice_id]/push_notification_records/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/notices/[notice_id]/push_notification_records/index.vue'),
                        /* no children */
                      },
                      {
                        path: ':id/edit',
                        name: '/sites/[site_id]/notices/[notice_id]/push_notification_records/[id].edit',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/notices/[notice_id]/push_notification_records/[id].edit.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/sites/[site_id]/notices/[notice_id]/push_notification_records/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/notices/[notice_id]/push_notification_records/new.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              },
              {
                path: 'new',
                name: '/sites/[site_id]/notices/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/notices/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'orders',
            /* internal name: '/sites/[site_id]/orders' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/orders/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/orders/index.vue'),
                /* no children */
              },
              {
                path: ':order_id',
                /* internal name: '/sites/[site_id]/orders/[order_id]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/sites/[site_id]/orders/[order_id]/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/orders/[order_id]/index.vue'),
                    /* no children */
                  },
                  {
                    path: 'edit',
                    name: '/sites/[site_id]/orders/[order_id]/edit',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/orders/[order_id]/edit.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          },
          {
            path: 'pages',
            /* internal name: '/sites/[site_id]/pages' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/pages/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/pages/index.vue'),
                /* no children */
              },
              {
                path: ':id/edit',
                name: '/sites/[site_id]/pages/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/pages/[id].edit.vue'),
                /* no children */
              },
              {
                path: 'new',
                name: '/sites/[site_id]/pages/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/pages/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'products',
            /* internal name: '/sites/[site_id]/products' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/products/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/products/index.vue'),
                /* no children */
              },
              {
                path: ':product_id',
                name: '/sites/[site_id]/products/[product_id]',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/products/[product_id].vue'),
                /* no children */
              },
              {
                path: ':product_id/edit',
                name: '/sites/[site_id]/products/[product_id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/products/[product_id].edit.vue'),
                /* no children */
              },
              {
                path: 'new',
                name: '/sites/[site_id]/products/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/products/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'promoters',
            /* internal name: '/sites/[site_id]/promoters' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/promoters/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/promoters/index.vue'),
                /* no children */
              },
              {
                path: ':promoter_id/edit',
                name: '/sites/[site_id]/promoters/[promoter_id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/promoters/[promoter_id].edit.vue'),
                /* no children */
              },
              {
                path: 'new',
                name: '/sites/[site_id]/promoters/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/promoters/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'regions',
            /* internal name: '/sites/[site_id]/regions' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/regions/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/regions/index.vue'),
                /* no children */
                meta: {
                  "preset": "fixed"
                }
              },
              {
                path: ':id',
                name: '/sites/[site_id]/regions/[id]',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/regions/[id].vue'),
                /* no children */
              },
              {
                path: ':id/edit',
                name: '/sites/[site_id]/regions/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/regions/[id].edit.vue'),
                /* no children */
              },
              {
                path: ':id/edit_cities',
                name: '/sites/[site_id]/regions/[id].edit_cities',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/regions/[id].edit_cities.vue'),
                /* no children */
              },
              {
                path: ':id/edit_textbook_folders',
                name: '/sites/[site_id]/regions/[id].edit_textbook_folders',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/regions/[id].edit_textbook_folders.vue'),
                /* no children */
              },
              {
                path: 'new',
                name: '/sites/[site_id]/regions/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/regions/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'school_feature_records',
            /* internal name: '/sites/[site_id]/school_feature_records' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/school_feature_records/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/school_feature_records/index.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'school_income_statistics',
            /* internal name: '/sites/[site_id]/school_income_statistics' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/school_income_statistics/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/school_income_statistics/index.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'schools',
            /* internal name: '/sites/[site_id]/schools' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/schools/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/index.vue'),
                /* no children */
              },
              {
                path: ':id/edit',
                name: '/sites/[site_id]/schools/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[id].edit.vue'),
                /* no children */
              },
              {
                path: ':school_id',
                /* internal name: '/sites/[site_id]/schools/[school_id]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/sites/[site_id]/schools/[school_id]/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/index.vue'),
                    /* no children */
                  },
                  {
                    path: 'clazzes',
                    /* internal name: '/sites/[site_id]/schools/[school_id]/clazzes' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/schools/[school_id]/clazzes/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/clazzes/index.vue'),
                        /* no children */
                      },
                      {
                        path: ':clazz_id',
                        /* internal name: '/sites/[site_id]/schools/[school_id]/clazzes/[clazz_id]' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: '/sites/[site_id]/schools/[school_id]/clazzes/[clazz_id]/',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/clazzes/[clazz_id]/index.vue'),
                            /* no children */
                          },
                          {
                            path: 'studentships',
                            /* internal name: '/sites/[site_id]/schools/[school_id]/clazzes/[clazz_id]/studentships' */
                            /* no component */
                            children: [
                              {
                                path: '',
                                name: '/sites/[site_id]/schools/[school_id]/clazzes/[clazz_id]/studentships/',
                                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/clazzes/[clazz_id]/studentships/index.vue'),
                                /* no children */
                              },
                              {
                                path: 'new',
                                name: '/sites/[site_id]/schools/[school_id]/clazzes/[clazz_id]/studentships/new',
                                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/clazzes/[clazz_id]/studentships/new.vue'),
                                /* no children */
                              }
                            ],
                          },
                          {
                            path: 'teacherships',
                            /* internal name: '/sites/[site_id]/schools/[school_id]/clazzes/[clazz_id]/teacherships' */
                            /* no component */
                            children: [
                              {
                                path: '',
                                name: '/sites/[site_id]/schools/[school_id]/clazzes/[clazz_id]/teacherships/',
                                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/clazzes/[clazz_id]/teacherships/index.vue'),
                                /* no children */
                              },
                              {
                                path: ':id/edit',
                                name: '/sites/[site_id]/schools/[school_id]/clazzes/[clazz_id]/teacherships/[id].edit',
                                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/clazzes/[clazz_id]/teacherships/[id].edit.vue'),
                                /* no children */
                              },
                              {
                                path: 'new',
                                name: '/sites/[site_id]/schools/[school_id]/clazzes/[clazz_id]/teacherships/new',
                                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/clazzes/[clazz_id]/teacherships/new.vue'),
                                /* no children */
                              }
                            ],
                          },
                          {
                            path: 'workathons',
                            /* internal name: '/sites/[site_id]/schools/[school_id]/clazzes/[clazz_id]/workathons' */
                            /* no component */
                            children: [
                              {
                                path: '',
                                name: '/sites/[site_id]/schools/[school_id]/clazzes/[clazz_id]/workathons/',
                                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/clazzes/[clazz_id]/workathons/index.vue'),
                                /* no children */
                              },
                              {
                                path: ':id/edit',
                                name: '/sites/[site_id]/schools/[school_id]/clazzes/[clazz_id]/workathons/[id].edit',
                                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/clazzes/[clazz_id]/workathons/[id].edit.vue'),
                                /* no children */
                              }
                            ],
                          }
                        ],
                      },
                      {
                        path: ':id/edit',
                        name: '/sites/[site_id]/schools/[school_id]/clazzes/[id].edit',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/clazzes/[id].edit.vue'),
                        /* no children */
                      },
                      {
                        path: 'batch_edit',
                        name: '/sites/[site_id]/schools/[school_id]/clazzes/batch_edit',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/clazzes/batch_edit.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/sites/[site_id]/schools/[school_id]/clazzes/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/clazzes/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'event_cases',
                    /* internal name: '/sites/[site_id]/schools/[school_id]/event_cases' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/schools/[school_id]/event_cases/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/event_cases/index.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'membership_batch_records',
                    /* internal name: '/sites/[site_id]/schools/[school_id]/membership_batch_records' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/schools/[school_id]/membership_batch_records/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/membership_batch_records/index.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/sites/[site_id]/schools/[school_id]/membership_batch_records/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/membership_batch_records/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'school_feature_records',
                    /* internal name: '/sites/[site_id]/schools/[school_id]/school_feature_records' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/schools/[school_id]/school_feature_records/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/school_feature_records/index.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/sites/[site_id]/schools/[school_id]/school_feature_records/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/school_feature_records/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'school_reports',
                    /* internal name: '/sites/[site_id]/schools/[school_id]/school_reports' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/schools/[school_id]/school_reports/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/school_reports/index.vue'),
                        /* no children */
                      },
                      {
                        path: ':id',
                        name: '/sites/[site_id]/schools/[school_id]/school_reports/[id]',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/school_reports/[id].vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/sites/[site_id]/schools/[school_id]/school_reports/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/school_reports/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'student_extra_info_imports',
                    /* internal name: '/sites/[site_id]/schools/[school_id]/student_extra_info_imports' */
                    /* no component */
                    children: [
                      {
                        path: 'new',
                        name: '/sites/[site_id]/schools/[school_id]/student_extra_info_imports/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/student_extra_info_imports/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'student_redistributions',
                    /* internal name: '/sites/[site_id]/schools/[school_id]/student_redistributions' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/schools/[school_id]/student_redistributions/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/student_redistributions/index.vue'),
                        /* no children */
                      },
                      {
                        path: ':id',
                        name: '/sites/[site_id]/schools/[school_id]/student_redistributions/[id]',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/student_redistributions/[id].vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/sites/[site_id]/schools/[school_id]/student_redistributions/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/student_redistributions/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'students',
                    /* internal name: '/sites/[site_id]/schools/[school_id]/students' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/schools/[school_id]/students/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/students/index.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/sites/[site_id]/schools/[school_id]/students/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/students/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'teachers',
                    /* internal name: '/sites/[site_id]/schools/[school_id]/teachers' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/schools/[school_id]/teachers/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/teachers/index.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/sites/[site_id]/schools/[school_id]/teachers/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/teachers/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'textbooks',
                    /* internal name: '/sites/[site_id]/schools/[school_id]/textbooks' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/schools/[school_id]/textbooks/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/textbooks/index.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/sites/[site_id]/schools/[school_id]/textbooks/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/textbooks/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'usage_reports',
                    /* internal name: '/sites/[site_id]/schools/[school_id]/usage_reports' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/schools/[school_id]/usage_reports/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/usage_reports/index.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/sites/[site_id]/schools/[school_id]/usage_reports/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/[school_id]/usage_reports/new.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              },
              {
                path: 'batch_edit',
                name: '/sites/[site_id]/schools/batch_edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/batch_edit.vue'),
                /* no children */
              },
              {
                path: 'new',
                name: '/sites/[site_id]/schools/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/schools/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'shengtong_exam_records',
            /* internal name: '/sites/[site_id]/shengtong_exam_records' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/shengtong_exam_records/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/shengtong_exam_records/index.vue'),
                /* no children */
              },
              {
                path: ':id',
                /* internal name: '/sites/[site_id]/shengtong_exam_records/[id]' */
                /* no component */
                children: [
                  {
                    path: 'edit',
                    name: '/sites/[site_id]/shengtong_exam_records/[id]/edit',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/shengtong_exam_records/[id]/edit.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          },
          {
            path: 'site_subject_relations',
            /* internal name: '/sites/[site_id]/site_subject_relations' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/site_subject_relations/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/site_subject_relations/index.vue'),
                /* no children */
              },
              {
                path: ':id/edit',
                name: '/sites/[site_id]/site_subject_relations/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/site_subject_relations/[id].edit.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'spreads',
            /* internal name: '/sites/[site_id]/spreads' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/spreads/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/spreads/index.vue'),
                /* no children */
              },
              {
                path: ':spread_id/edit',
                name: '/sites/[site_id]/spreads/[spread_id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/spreads/[spread_id].edit.vue'),
                /* no children */
              },
              {
                path: 'new',
                name: '/sites/[site_id]/spreads/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/spreads/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'students',
            /* internal name: '/sites/[site_id]/students' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/students/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/students/index.vue'),
                /* no children */
              },
              {
                path: ':student_id',
                /* internal name: '/sites/[site_id]/students/[student_id]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/sites/[site_id]/students/[student_id]/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/students/[student_id]/index.vue'),
                    /* no children */
                  },
                  {
                    path: 'edit',
                    name: '/sites/[site_id]/students/[student_id]/edit',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/students/[student_id]/edit.vue'),
                    /* no children */
                  },
                  {
                    path: 'orders',
                    /* internal name: '/sites/[site_id]/students/[student_id]/orders' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/students/[student_id]/orders/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/students/[student_id]/orders/index.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/sites/[site_id]/students/[student_id]/orders/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/students/[student_id]/orders/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'personations',
                    /* internal name: '/sites/[site_id]/students/[student_id]/personations' */
                    /* no component */
                    children: [
                      {
                        path: 'new',
                        name: '/sites/[site_id]/students/[student_id]/personations/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/students/[student_id]/personations/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'point_records',
                    /* internal name: '/sites/[site_id]/students/[student_id]/point_records' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/students/[student_id]/point_records/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/students/[student_id]/point_records/index.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/sites/[site_id]/students/[student_id]/point_records/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/students/[student_id]/point_records/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'wechat_authentications',
                    /* internal name: '/sites/[site_id]/students/[student_id]/wechat_authentications' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/students/[student_id]/wechat_authentications/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/students/[student_id]/wechat_authentications/index.vue'),
                        /* no children */
                      },
                      {
                        path: ':id',
                        /* internal name: '/sites/[site_id]/students/[student_id]/wechat_authentications/[id]' */
                        /* no component */
                        children: [
                          {
                            path: 'edit',
                            name: '/sites/[site_id]/students/[student_id]/wechat_authentications/[id]/edit',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/students/[student_id]/wechat_authentications/[id]/edit.vue'),
                            /* no children */
                          }
                        ],
                      }
                    ],
                  }
                ],
              }
            ],
          },
          {
            path: 'teachers',
            /* internal name: '/sites/[site_id]/teachers' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/teachers/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/teachers/index.vue'),
                /* no children */
              },
              {
                path: ':teacher_id',
                /* internal name: '/sites/[site_id]/teachers/[teacher_id]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/sites/[site_id]/teachers/[teacher_id]/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/teachers/[teacher_id]/index.vue'),
                    /* no children */
                  },
                  {
                    path: 'edit',
                    name: '/sites/[site_id]/teachers/[teacher_id]/edit',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/teachers/[teacher_id]/edit.vue'),
                    /* no children */
                  },
                  {
                    path: 'orders',
                    /* internal name: '/sites/[site_id]/teachers/[teacher_id]/orders' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/teachers/[teacher_id]/orders/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/teachers/[teacher_id]/orders/index.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/sites/[site_id]/teachers/[teacher_id]/orders/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/teachers/[teacher_id]/orders/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'personations',
                    /* internal name: '/sites/[site_id]/teachers/[teacher_id]/personations' */
                    /* no component */
                    children: [
                      {
                        path: 'new',
                        name: '/sites/[site_id]/teachers/[teacher_id]/personations/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/teachers/[teacher_id]/personations/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'point_records',
                    /* internal name: '/sites/[site_id]/teachers/[teacher_id]/point_records' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/teachers/[teacher_id]/point_records/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/teachers/[teacher_id]/point_records/index.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/sites/[site_id]/teachers/[teacher_id]/point_records/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/teachers/[teacher_id]/point_records/new.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              }
            ],
          },
          {
            path: 'teleporters',
            /* internal name: '/sites/[site_id]/teleporters' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/teleporters/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/teleporters/index.vue'),
                /* no children */
              },
              {
                path: ':teleporter_id/edit',
                name: '/sites/[site_id]/teleporters/[teleporter_id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/teleporters/[teleporter_id].edit.vue'),
                /* no children */
              },
              {
                path: 'new',
                name: '/sites/[site_id]/teleporters/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/teleporters/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'territories',
            /* internal name: '/sites/[site_id]/territories' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/territories/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/territories/index.vue'),
                /* no children */
                meta: {
                  "preset": "fixed"
                }
              },
              {
                path: ':territory_id',
                /* internal name: '/sites/[site_id]/territories/[territory_id]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/sites/[site_id]/territories/[territory_id]/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/territories/[territory_id]/index.vue'),
                    /* no children */
                  },
                  {
                    path: 'contracts',
                    /* internal name: '/sites/[site_id]/territories/[territory_id]/contracts' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/territories/[territory_id]/contracts/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/territories/[territory_id]/contracts/index.vue'),
                        /* no children */
                      },
                      {
                        path: ':contract_id/edit',
                        name: '/sites/[site_id]/territories/[territory_id]/contracts/[contract_id].edit',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/territories/[territory_id]/contracts/[contract_id].edit.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/sites/[site_id]/territories/[territory_id]/contracts/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/territories/[territory_id]/contracts/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'edit',
                    name: '/sites/[site_id]/territories/[territory_id]/edit',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/territories/[territory_id]/edit.vue'),
                    /* no children */
                  },
                  {
                    path: 'events',
                    /* internal name: '/sites/[site_id]/territories/[territory_id]/events' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/territories/[territory_id]/events/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/territories/[territory_id]/events/index.vue'),
                        /* no children */
                      },
                      {
                        path: ':event_id',
                        /* internal name: '/sites/[site_id]/territories/[territory_id]/events/[event_id]' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: '/sites/[site_id]/territories/[territory_id]/events/[event_id]/',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/territories/[territory_id]/events/[event_id]/index.vue'),
                            /* no children */
                          },
                          {
                            path: 'edit',
                            name: '/sites/[site_id]/territories/[territory_id]/events/[event_id]/edit',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/territories/[territory_id]/events/[event_id]/edit.vue'),
                            /* no children */
                          },
                          {
                            path: 'event_cases',
                            /* internal name: '/sites/[site_id]/territories/[territory_id]/events/[event_id]/event_cases' */
                            /* no component */
                            children: [
                              {
                                path: ':event_case_id/edit',
                                name: '/sites/[site_id]/territories/[territory_id]/events/[event_id]/event_cases/[event_case_id].edit',
                                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/territories/[territory_id]/events/[event_id]/event_cases/[event_case_id].edit.vue'),
                                /* no children */
                              },
                              {
                                path: 'new',
                                name: '/sites/[site_id]/territories/[territory_id]/events/[event_id]/event_cases/new',
                                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/territories/[territory_id]/events/[event_id]/event_cases/new.vue'),
                                /* no children */
                              }
                            ],
                          },
                          {
                            path: 'event_items',
                            /* internal name: '/sites/[site_id]/territories/[territory_id]/events/[event_id]/event_items' */
                            /* no component */
                            children: [
                              {
                                path: ':event_item_id/edit',
                                name: '/sites/[site_id]/territories/[territory_id]/events/[event_id]/event_items/[event_item_id].edit',
                                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/territories/[territory_id]/events/[event_id]/event_items/[event_item_id].edit.vue'),
                                /* no children */
                              },
                              {
                                path: 'new',
                                name: '/sites/[site_id]/territories/[territory_id]/events/[event_id]/event_items/new',
                                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/territories/[territory_id]/events/[event_id]/event_items/new.vue'),
                                /* no children */
                              }
                            ],
                          }
                        ],
                      },
                      {
                        path: 'new',
                        name: '/sites/[site_id]/territories/[territory_id]/events/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/territories/[territory_id]/events/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'schools',
                    /* internal name: '/sites/[site_id]/territories/[territory_id]/schools' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/sites/[site_id]/territories/[territory_id]/schools/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/territories/[territory_id]/schools/index.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              },
              {
                path: 'new',
                name: '/sites/[site_id]/territories/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/territories/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'unconfirmed_bundles',
            /* internal name: '/sites/[site_id]/unconfirmed_bundles' */
            /* no component */
            children: [
              {
                path: '',
                name: '/sites/[site_id]/unconfirmed_bundles/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id]/unconfirmed_bundles/index.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: ':site_id/edit',
        name: '/sites/[site_id].edit',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/sites/[site_id].edit.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/subjects',
    /* internal name: '/subjects' */
    /* no component */
    children: [
      {
        path: '',
        name: '/subjects/',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/index.vue'),
        /* no children */
      },
      {
        path: ':subject_id',
        /* internal name: '/subjects/[subject_id]' */
        /* no component */
        children: [
          {
            path: 'courseware_families',
            /* internal name: '/subjects/[subject_id]/courseware_families' */
            /* no component */
            children: [
              {
                path: '',
                name: '/subjects/[subject_id]/courseware_families/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/courseware_families/index.vue'),
                /* no children */
              },
              {
                path: ':courseware_family_id/edit',
                name: '/subjects/[subject_id]/courseware_families/[courseware_family_id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/courseware_families/[courseware_family_id].edit.vue'),
                /* no children */
              },
              {
                path: 'new',
                name: '/subjects/[subject_id]/courseware_families/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/courseware_families/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'coursewares',
            /* internal name: '/subjects/[subject_id]/coursewares' */
            /* no component */
            children: [
              {
                path: '',
                name: '/subjects/[subject_id]/coursewares/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/coursewares/index.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'editions',
            /* internal name: '/subjects/[subject_id]/editions' */
            /* no component */
            children: [
              {
                path: '',
                name: '/subjects/[subject_id]/editions/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/editions/index.vue'),
                /* no children */
              },
              {
                path: ':edition_id/edit',
                name: '/subjects/[subject_id]/editions/[edition_id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/editions/[edition_id].edit.vue'),
                /* no children */
              },
              {
                path: 'new',
                name: '/subjects/[subject_id]/editions/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/editions/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'knowledges',
            /* internal name: '/subjects/[subject_id]/knowledges' */
            /* no component */
            children: [
              {
                path: '',
                name: '/subjects/[subject_id]/knowledges/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/knowledges/index.vue'),
                /* no children */
              },
              {
                path: ':id',
                name: '/subjects/[subject_id]/knowledges/[id]',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/knowledges/[id].vue'),
                /* no children */
              },
              {
                path: ':id/edit',
                name: '/subjects/[subject_id]/knowledges/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/knowledges/[id].edit.vue'),
                /* no children */
              },
              {
                path: 'new',
                name: '/subjects/[subject_id]/knowledges/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/knowledges/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'lesson_plans',
            /* internal name: '/subjects/[subject_id]/lesson_plans' */
            /* no component */
            children: [
              {
                path: '',
                name: '/subjects/[subject_id]/lesson_plans/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/lesson_plans/index.vue'),
                /* no children */
              },
              {
                path: ':lesson_plan_id',
                /* internal name: '/subjects/[subject_id]/lesson_plans/[lesson_plan_id]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/subjects/[subject_id]/lesson_plans/[lesson_plan_id]/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/lesson_plans/[lesson_plan_id]/index.vue'),
                    /* no children */
                  },
                  {
                    path: 'edit',
                    name: '/subjects/[subject_id]/lesson_plans/[lesson_plan_id]/edit',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/lesson_plans/[lesson_plan_id]/edit.vue'),
                    /* no children */
                  },
                  {
                    path: 'lesson_attachments',
                    /* internal name: '/subjects/[subject_id]/lesson_plans/[lesson_plan_id]/lesson_attachments' */
                    /* no component */
                    children: [
                      {
                        path: ':id',
                        /* internal name: '/subjects/[subject_id]/lesson_plans/[lesson_plan_id]/lesson_attachments/[id]' */
                        /* no component */
                        children: [
                          {
                            path: 'edit',
                            name: '/subjects/[subject_id]/lesson_plans/[lesson_plan_id]/lesson_attachments/[id]/edit',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/lesson_plans/[lesson_plan_id]/lesson_attachments/[id]/edit.vue'),
                            /* no children */
                          }
                        ],
                      },
                      {
                        path: 'new',
                        name: '/subjects/[subject_id]/lesson_plans/[lesson_plan_id]/lesson_attachments/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/lesson_plans/[lesson_plan_id]/lesson_attachments/new.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              },
              {
                path: 'new',
                name: '/subjects/[subject_id]/lesson_plans/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/lesson_plans/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'question_groups',
            /* internal name: '/subjects/[subject_id]/question_groups' */
            /* no component */
            children: [
              {
                path: '',
                name: '/subjects/[subject_id]/question_groups/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/question_groups/index.vue'),
                /* no children */
              },
              {
                path: ':id/edit',
                name: '/subjects/[subject_id]/question_groups/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/question_groups/[id].edit.vue'),
                /* no children */
              },
              {
                path: ':question_group_id',
                /* internal name: '/subjects/[subject_id]/question_groups/[question_group_id]' */
                /* no component */
                children: [
                  {
                    path: 'categories',
                    /* internal name: '/subjects/[subject_id]/question_groups/[question_group_id]/categories' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/subjects/[subject_id]/question_groups/[question_group_id]/categories/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/question_groups/[question_group_id]/categories/index.vue'),
                        /* no children */
                      },
                      {
                        path: ':id/edit',
                        name: '/subjects/[subject_id]/question_groups/[question_group_id]/categories/[id].edit',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/question_groups/[question_group_id]/categories/[id].edit.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/subjects/[subject_id]/question_groups/[question_group_id]/categories/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/question_groups/[question_group_id]/categories/new.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              },
              {
                path: 'new',
                name: '/subjects/[subject_id]/question_groups/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/question_groups/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'question_recheck_records',
            /* internal name: '/subjects/[subject_id]/question_recheck_records' */
            /* no component */
            children: [
              {
                path: '',
                name: '/subjects/[subject_id]/question_recheck_records/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/question_recheck_records/index.vue'),
                /* no children */
              },
              {
                path: 'new',
                name: '/subjects/[subject_id]/question_recheck_records/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/question_recheck_records/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'questions',
            /* internal name: '/subjects/[subject_id]/questions' */
            /* no component */
            children: [
              {
                path: '',
                name: '/subjects/[subject_id]/questions/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/questions/index.vue'),
                /* no children */
              },
              {
                path: ':id',
                name: '/subjects/[subject_id]/questions/[id]',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/questions/[id].vue'),
                /* no children */
              },
              {
                path: ':id/edit',
                name: '/subjects/[subject_id]/questions/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/questions/[id].edit.vue'),
                /* no children */
              },
              {
                path: ':question_id',
                /* internal name: '/subjects/[subject_id]/questions/[question_id]' */
                /* no component */
                children: [
                  {
                    path: 'question_options',
                    /* internal name: '/subjects/[subject_id]/questions/[question_id]/question_options' */
                    /* no component */
                    children: [
                      {
                        path: ':id/edit',
                        name: '/subjects/[subject_id]/questions/[question_id]/question_options/[id].edit',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/questions/[question_id]/question_options/[id].edit.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/subjects/[subject_id]/questions/[question_id]/question_options/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/questions/[question_id]/question_options/new.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              }
            ],
          },
          {
            path: 'suites',
            /* internal name: '/subjects/[subject_id]/suites' */
            /* no component */
            children: [
              {
                path: '',
                name: '/subjects/[subject_id]/suites/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/suites/index.vue'),
                /* no children */
              },
              {
                path: ':id',
                name: '/subjects/[subject_id]/suites/[id]',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/suites/[id].vue'),
                /* no children */
              },
              {
                path: ':id/edit',
                name: '/subjects/[subject_id]/suites/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/suites/[id].edit.vue'),
                /* no children */
              },
              {
                path: 'new',
                name: '/subjects/[subject_id]/suites/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/suites/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'textbook_folders',
            /* internal name: '/subjects/[subject_id]/textbook_folders' */
            /* no component */
            children: [
              {
                path: ':id/edit',
                name: '/subjects/[subject_id]/textbook_folders/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/textbook_folders/[id].edit.vue'),
                /* no children */
              },
              {
                path: 'new',
                name: '/subjects/[subject_id]/textbook_folders/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/textbook_folders/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'textbooks',
            /* internal name: '/subjects/[subject_id]/textbooks' */
            /* no component */
            children: [
              {
                path: '',
                name: '/subjects/[subject_id]/textbooks/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/textbooks/index.vue'),
                /* no children */
              },
              {
                path: ':id',
                name: '/subjects/[subject_id]/textbooks/[id]',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/textbooks/[id].vue'),
                /* no children */
              },
              {
                path: ':id/copy_coursewares',
                name: '/subjects/[subject_id]/textbooks/[id].copy_coursewares',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/textbooks/[id].copy_coursewares.vue'),
                /* no children */
              },
              {
                path: ':id/edit',
                name: '/subjects/[subject_id]/textbooks/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/textbooks/[id].edit.vue'),
                /* no children */
              },
              {
                path: ':textbook_id',
                /* internal name: '/subjects/[subject_id]/textbooks/[textbook_id]' */
                /* no component */
                children: [
                  {
                    path: 'cosplays',
                    /* internal name: '/subjects/[subject_id]/textbooks/[textbook_id]/cosplays' */
                    /* no component */
                    children: [
                      {
                        path: ':id',
                        name: '/subjects/[subject_id]/textbooks/[textbook_id]/cosplays/[id]',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/textbooks/[textbook_id]/cosplays/[id].vue'),
                        /* no children */
                      },
                      {
                        path: ':id/edit',
                        name: '/subjects/[subject_id]/textbooks/[textbook_id]/cosplays/[id].edit',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/textbooks/[textbook_id]/cosplays/[id].edit.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/subjects/[subject_id]/textbooks/[textbook_id]/cosplays/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/textbooks/[textbook_id]/cosplays/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'coursewares',
                    /* internal name: '/subjects/[subject_id]/textbooks/[textbook_id]/coursewares' */
                    /* no component */
                    children: [
                      {
                        path: 'batch_edit',
                        name: '/subjects/[subject_id]/textbooks/[textbook_id]/coursewares/batch_edit',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/textbooks/[textbook_id]/coursewares/batch_edit.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'interactive_readings',
                    /* internal name: '/subjects/[subject_id]/textbooks/[textbook_id]/interactive_readings' */
                    /* no component */
                    children: [
                      {
                        path: ':id',
                        name: '/subjects/[subject_id]/textbooks/[textbook_id]/interactive_readings/[id]',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/textbooks/[textbook_id]/interactive_readings/[id].vue'),
                        /* no children */
                      },
                      {
                        path: ':id/edit',
                        name: '/subjects/[subject_id]/textbooks/[textbook_id]/interactive_readings/[id].edit',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/textbooks/[textbook_id]/interactive_readings/[id].edit.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/subjects/[subject_id]/textbooks/[textbook_id]/interactive_readings/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/textbooks/[textbook_id]/interactive_readings/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'repeat_texts',
                    /* internal name: '/subjects/[subject_id]/textbooks/[textbook_id]/repeat_texts' */
                    /* no component */
                    children: [
                      {
                        path: ':id',
                        name: '/subjects/[subject_id]/textbooks/[textbook_id]/repeat_texts/[id]',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/textbooks/[textbook_id]/repeat_texts/[id].vue'),
                        /* no children */
                      },
                      {
                        path: ':id/edit',
                        name: '/subjects/[subject_id]/textbooks/[textbook_id]/repeat_texts/[id].edit',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/textbooks/[textbook_id]/repeat_texts/[id].edit.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/subjects/[subject_id]/textbooks/[textbook_id]/repeat_texts/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/textbooks/[textbook_id]/repeat_texts/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'speech_texts',
                    /* internal name: '/subjects/[subject_id]/textbooks/[textbook_id]/speech_texts' */
                    /* no component */
                    children: [
                      {
                        path: ':id',
                        name: '/subjects/[subject_id]/textbooks/[textbook_id]/speech_texts/[id]',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/textbooks/[textbook_id]/speech_texts/[id].vue'),
                        /* no children */
                      },
                      {
                        path: ':id/edit',
                        name: '/subjects/[subject_id]/textbooks/[textbook_id]/speech_texts/[id].edit',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/textbooks/[textbook_id]/speech_texts/[id].edit.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/subjects/[subject_id]/textbooks/[textbook_id]/speech_texts/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/textbooks/[textbook_id]/speech_texts/new.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'test_papers',
                    /* internal name: '/subjects/[subject_id]/textbooks/[textbook_id]/test_papers' */
                    /* no component */
                    children: [
                      {
                        path: ':id',
                        name: '/subjects/[subject_id]/textbooks/[textbook_id]/test_papers/[id]',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/textbooks/[textbook_id]/test_papers/[id].vue'),
                        /* no children */
                      },
                      {
                        path: ':id/edit',
                        name: '/subjects/[subject_id]/textbooks/[textbook_id]/test_papers/[id].edit',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/textbooks/[textbook_id]/test_papers/[id].edit.vue'),
                        /* no children */
                      },
                      {
                        path: 'new',
                        name: '/subjects/[subject_id]/textbooks/[textbook_id]/test_papers/new',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/textbooks/[textbook_id]/test_papers/new.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              },
              {
                path: 'batch_edit',
                name: '/subjects/[subject_id]/textbooks/batch_edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/textbooks/batch_edit.vue'),
                /* no children */
              },
              {
                path: 'new',
                name: '/subjects/[subject_id]/textbooks/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id]/textbooks/new.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: ':subject_id/edit',
        name: '/subjects/[subject_id].edit',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/subjects/[subject_id].edit.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/test_papers',
    /* internal name: '/test_papers' */
    /* no component */
    children: [
      {
        path: ':test_paper_id',
        /* internal name: '/test_papers/[test_paper_id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/test_papers/[test_paper_id]/',
            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/test_papers/[test_paper_id]/index.vue'),
            /* no children */
          },
          {
            path: 'question_batch_records',
            /* internal name: '/test_papers/[test_paper_id]/question_batch_records' */
            /* no component */
            children: [
              {
                path: ':id/preview',
                name: '/test_papers/[test_paper_id]/question_batch_records/[id].preview',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/test_papers/[test_paper_id]/question_batch_records/[id].preview.vue'),
                /* no children */
              },
              {
                path: 'new',
                name: '/test_papers/[test_paper_id]/question_batch_records/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/test_papers/[test_paper_id]/question_batch_records/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'test_paper_entries',
            /* internal name: '/test_papers/[test_paper_id]/test_paper_entries' */
            /* no component */
            children: [
              {
                path: ':id/edit',
                name: '/test_papers/[test_paper_id]/test_paper_entries/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/test_papers/[test_paper_id]/test_paper_entries/[id].edit.vue'),
                /* no children */
              },
              {
                path: 'new',
                name: '/test_papers/[test_paper_id]/test_paper_entries/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/test_papers/[test_paper_id]/test_paper_entries/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'test_paper_partitions',
            /* internal name: '/test_papers/[test_paper_id]/test_paper_partitions' */
            /* no component */
            children: [
              {
                path: ':id/edit',
                name: '/test_papers/[test_paper_id]/test_paper_partitions/[id].edit',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/test_papers/[test_paper_id]/test_paper_partitions/[id].edit.vue'),
                /* no children */
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/tickets',
    /* internal name: '/tickets' */
    /* no component */
    children: [
      {
        path: '',
        name: '/tickets/',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/tickets/index.vue'),
        /* no children */
      },
      {
        path: ':ticket_id/edit',
        name: '/tickets/[ticket_id].edit',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/tickets/[ticket_id].edit.vue'),
        /* no children */
      },
      {
        path: 'new',
        name: '/tickets/new',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/backend/backend/app/javascript/backend/pages/tickets/new.vue'),
        /* no children */
      }
    ],
  }
]

export function handleHotUpdate(_router, _hotUpdateCallback) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
    import.meta.hot.data.router_hotUpdateCallback = _hotUpdateCallback
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    // call the hotUpdateCallback for custom updates
    import.meta.hot.data.router_hotUpdateCallback?.(mod.routes)
    const route = router.currentRoute.value
    router.replace({
      ...route,
      // NOTE: we should be able to just do ...route but the router
      // currently skips resolving and can give errors with renamed routes
      // so we explicitly set remove matched and name
      name: undefined,
      matched: undefined,
      force: true
    })
  })
}

